.error-message-todo {
    color: red;
    font-weight: bolder;
    font-size: 12px;
}

.label-new-todo {
    margin-right: 10px;
}

.new-todo-input-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input-create-todo-name {
    width: 100%;
    border-radius: 20px;
    border: .5px solid rgba(0, 0, 0, 0.39);
    padding: 5px;
}

.input-create-todo-priority {
    width: 100%;
    border-radius: 20px;
    border: .5px solid rgba(0, 0, 0, 0.39);
    padding: 5px;
}

.input-create-todo-notes {
    height: 120px;
    width: 100%;
    padding: 8px;
    /* resize: vertical; */
    border-radius: 10px;
    border: .5px solid rgba(0, 0, 0, 0.39);
    font-size: 16px;
    background-color: transparent;
    margin-bottom: 5px;
}

.input-create-todo-goal {
    width: 100%;
    border-radius: 20px;
    border: .5px solid rgba(0, 0, 0, 0.39);
    padding: 5px;
}

.todo-submit-btn {
    position: relative;
    left: 20vw;
    margin-top: 5px;
    width: 21%;
}
