.goals-overview {
    display: flex;
    flex-direction: row;
    position: relative;
    left: -15;
}

.goals-overview-heading {
    font-size: 2em;
    margin-top: 10vh;
}

.goals-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.goal-contents {
    padding: 12px;
}

.goal-card {
    width: 250px;
    margin: 20px;
    padding: 5px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    list-style: none;
}

.goal-card:hover {
    transform: scale(1.05);
}

.goal-card .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.goal-card .description {
    font-size: 14px;
    color: #666666;
    margin-bottom: 1rem;
}

.goal-card .goal-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-btn-goal {
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.modal-btn-goal:hover {
    background-color: #68bbfe;
}


.goal-card .delete-button {
    background-color: transparent;
    color: #000000;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.goal-card .delete-button:hover {
    background-color: #ff4242;
}

.goal-card .open-modal-button {
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}


.timeframe-clock {
    margin: 10px;
    padding: 4px;
}

.clock,
.hourglass,
.bullseye {
    margin-right: 8px;
}

.delete-button:hover {
    cursor: pointer;
}


/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.goals-overview,
.goals-list {
    animation: fadeIn 0.5s ease forwards;
}
