.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form h1 {
    margin-bottom: 20px;
    text-align: center;
}

.login-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.login-form label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.login-form input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-top: 5px;
}

.login-form button[type="submit"] {
    background-color: #8c4dff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}
