.new-note-heading {
    margin-top: 5vw;
}

.create-note-form {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 10vh;
    padding: 20px;
    background-color: hsl(0, 0%, 96%);
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.new-note-heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.note-form-errors {
    list-style-type: none;
    color: red;
    margin-bottom: 10px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.title-input-notes,
.content-input-notes {
    width: 95%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 15px;
    font-size: 16px;
}

.content-input-notes {
    height: 200px;
}

.notes-submit-button {
    background-color: #8c4dff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 40vw;
}

button[type='submit']:hover {
    transform: scale(1.05);
    transition: ease-in-out .2s;

}


/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.create-note-form {
    animation: fadeIn 0.5s ease forwards;
}
