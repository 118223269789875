.slideshow-controls {
    display: flex;
    align-items: center;
}

.slideshow-button {
    background-color: transparent;
    border: none;
    color: #555555;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.slideshow-button:hover {
    color: #333333;
    transform: scale(1.1);

}

.daily-planner-heading {
    margin-top: 14vh;
}


.subheading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 75%;
}

.time {
    font-size: 12px;
    margin-right: 10px;
}

.time-slots-start-end {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 -5px;
}

.time-slot {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.time-slot-field.completed {
    background-color: #8bc34a;
    color: #ffffff;
}

.time-slot p {
    margin-right: 10px;
}

.time-slot-field {
    width: 100vh;
    height: 14px;
    background-color: rgba(224, 224, 224, 0.529);
    border: none;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.time-slot-field:focus {
    outline: none;
    cursor: default;
}

.time-slot-field:hover {
    cursor: default;
}

.delete-button {
    background-color: transparent;
    border: none;
    transition: transform 0.3s;
}

.delete-button:hover {
    animation: shake 0.5s infinite;
}

/* Add this CSS code to your existing styles */

/* Priority Styles */
.priority {
    display: inline-block;
    margin-left: 10px;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
}

.priority.low {
    background-color: #ffd9d9;
    color: #e74c3c;
}

.priority.medium {
    background-color: #e0d9ff;
    color: #8e44ad;
}

.priority.high {
    background-color: #d2f2c3;
    color: #27ae60;
}

/* CHECKBOX */
.checkbox-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.custom-checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #555555;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    margin-left: 5px;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.custom-checkbox.checked {
    border-color: #8bc34a;
    /* Green color for checked state */
}

.custom-checkbox .checkmark {
    position: absolute;
    top: 2px;
    left: 6px;
    width: 4px;
    height: 8px;
    border: solid #555555;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
}

.custom-checkbox.checked .checkmark {
    display: block;
}

/* KEYFRAMES */
@keyframes shake {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(-2px) rotate(-2deg);
    }

    20% {
        transform: translateX(2px) rotate(2deg);
    }

    30% {
        transform: translateX(-2px) rotate(-2deg);
    }

    40% {
        transform: translateX(2px) rotate(2deg);
    }

    50% {
        transform: translateX(-1px) rotate(-1deg);
    }

    60% {
        transform: translateX(1px) rotate(1deg);
    }

    70% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
}

/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.daily-planner,
.time-slots-start-end {
    animation: fadeIn 0.5s ease forwards;
}

/* MEDIA QUERIES */

@media screen and (max-width: 768px) {
    .daily-planner {
        margin: 20px;
        margin-right: 10px;
    }

    .daily-planner-heading {
        margin-top: 10vh;
    }

    .subheading {
        width: 90%;
    }

    .time {
        font-size: 10px;
        margin-right: 5px;
    }

    .time-slot-field {
        width: 80vh;
    }

    .custom-checkbox {
        width: 16px;
        height: 16px;
        margin-left: 3px;
    }

}

@media screen and (max-width: 480px) {
    .daily-planner-heading {
        margin-top: 8vh;
    }

    .subheading {
        width: 95%;
    }

    .time {
        font-size: 8px;
        margin-right: 3px;
    }

    .time-slot-field {
        width: 60vh;
    }

    .custom-checkbox {
        width: 14px;
        height: 14px;
        margin-left: 2px;
    }

}
