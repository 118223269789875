.notes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
}

.notes-heading-new-note {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5vh;
}

.notes-heading {
    margin-top: 10vh;
    font-size: 2em;


}
.notes-title-update-delete-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.notes-overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.new-note-button {
    margin-right: 5vh;
    margin-top: 2vh;
    font-size: 20px;
}

.note-card {
    width: 100%;
    max-width: 600px;
    margin: 10px 0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.note-title {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 8px;
}

.note-content {
    margin-top: 8px;
}

.note-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.new-note-button:hover {
    transform: scale(1.1);
    transition: ease-in-out .2s;
}


/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.notes-container,
.notes-overview,
.notes-heading {
    animation: fadeIn 0.5s ease forwards;
}
