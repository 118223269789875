.App {
    display: grid;
    min-height: 100vh;
    grid-template-rows: 30px auto;
    padding: none;
    margin: -10px;
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
}

.Content {
    grid-column: 1;
    margin-left: 100px;
    font-family: 'Poppins', sans-serif;

}


input,
button,
textarea,
select,
option {
    font-family: 'Poppins', sans-serif;

}
