.profile-picture-preview {
    height: 30px;
    width: 30px;
}

.error-message {
    color: red;
    font-weight: bolder;
}

.modal-content-wrapper {
    position: relative;
    bottom: 60;
}

/* LOADING SYMBOL FOR SIGNUP */
.loading-symbol {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
}

.loading-symbol::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 1px;
    animation: loading 1.2s infinite ease-in-out;
}

.loading-symbol::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 11px;
    animation: loading 1.2s infinite ease-in-out;
}

.input-icon-container {
    position: relative;
}

.signup-form-input {
    padding-right: 40px;
}

.input-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #ccc;
}

.dropzone:hover {
    cursor: pointer;
    color: gray;
    transform: scale(1.01);
    transition: ease-in-out .1s;
}

/* ANIMATIONS */
@keyframes loading {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}
