#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  z-index: 100;
  font-family: 'Poppins', sans-serif;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  width: 30vw;
  /* max-width: 70vw; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}


#modal-content button {
  background-color: #8c4dff;
  color: #fff;
  padding: 10px 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

#modal-content input {
  color: #000000;
  margin-left: 5px;
  margin-bottom: 5px;
}
#modal-content textarea {
  color: #000000;
  margin-left: 5px;
  margin-top: 5px;
  height: 30px;
}

#modal-content select {
  margin-left: 5px;
  margin-bottom: 5px;
}
