.navigation {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}

.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  /* background-color: #8c4dff; */
  width: 30px;
}

.navigation-container-profile {
  position: relative;
  top: 10px;
}

.left-side-bar {
  background-color: #8c4dff;
  width: 40px;
  height: 350vh;
  margin-left: 2px;
  padding: none;
}

.btn {
  cursor: pointer;
  margin-top: 2px;
}

.profile-icon {
  font-size: 20px;
  padding: 5px;
}

.logout-settings-nav-bar {
  margin-top: 10vh;
}

.navigation-item {
  margin-right: 10px;
  text-decoration: none;
  background-color: transparent;
  font-size: 20px;
}

.modal-btn {
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  transition: transform 0.2s;
}

.modal-btn:hover {
  transform: scale(1.1);
}

.navigation-link {
  text-decoration: none;
  color: #000;
}
.profile-dropdown {
  position: relative;
  top: 34px;
  left: 88px;
  background: rgba(255, 255, 255, 0.433);
  width: 15vw;
  z-index: 1;
  height: fit-content;
  overflow-y: auto;
  font-weight: bold;
}

.hidden {
  display: none;
}

.profile-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 12px;
}

button .profile-icon .open {
  position: relative;
  top: 30px;
}

.profile-right-nav {
  display: flex;
  flex-direction: column;
  margin: none;
}

.flex-column {
  flex-direction: row-reverse;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 15px;
}

.profile-dropdown li {
  list-style-type: none;
}

.profile-dropdown button {
  display: block;
  width: 60%;
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #8c4dff;
  color: #fff;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.profile-dropdown button:hover {
  background-color: #6b23f0ef;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

.navigation-items-logged-in {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;
}

.navigation-item:hover {
  transform: scale(1.1);
  cursor: pointer;
}


.welcome-quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 5vh;
  margin-top: 20px;
}


.welcome-msg {
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
}

.quote {
  font-size: 10px;
  margin: 0;
}
.profile-picture {
  margin-right: 15px;
}
