.daily-overview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.daily-overview-heading {
    margin-top: 10vh;
}

.daily-overview-date-time {
    display: flex;
    flex-direction: row;
}

.daily-overview-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.daily-overview-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.current-date {
    margin-right: 10px;
    margin-left: 10px;
}

.current-time {
    margin-left: 10px;
}

.goals-section-overview,
.reminders-section,
.coming-soon-section,
.create-note-section {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    margin-right: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.goals-section-overview {
    grid-column: 1 / span 2;
}

.goals-header-overview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.see-all-link {
    color: #333;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s ease;
}

.goal-list-overview {
    list-style-type: none;
    padding: 0;
}

.goal-progress-section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #555;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #8b4dff60;
    padding: 8px;
}

.goal-name {
    flex-grow: 1;
    font-weight: bold;
    font-size: 14px;
}

.progress-bar {
    height: 10px;
    background-color: #ddd;
    width: 70vh;
    border-radius: 5px;
    margin-right: 20px;
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #7cb342;
    transition: width 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    padding-right: 5px;
}

.reminders-section {
grid-column: 1/ span 1;
}

.reminders-list {
    list-style: square;
    text-decoration: none;
    margin-right: 20px;

}

.reminders-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
    padding: 0;
}

.reminder-card {
    background-color: #45e49122;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
    position: relative;
    width: 160px;
}

.reminder-card.Low {
    background-color: #e4924522;
}

.reminder-card.Medium {
    background-color: #9745e422;
}


.reminder-card.High {
    background-color: #45e49122;
}

.reminder-content {
    margin-bottom: 10px;
}

.reminder-name {
    font-weight: bold;
}

.reminder-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
}

.reminder-header-overview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.reminder-title-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 5vh;
}

.new-reminder-button {
    margin-left: 10px;
}

.coming-soon-section {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    grid-column: 1 / span 2;
}

.goals-title-overview {
    margin-bottom: 10px;
    color: #333;
}

.goal-list-overview {
    list-style-type: none;
    padding: 0;
}

/* Hover animations */
.goal-progress-section:hover {
    transform: scale(1.01);
    transition: transform 0.1s ease;
    cursor: default;
}

.reminder-card:hover {
    transition: transform 0.1s ease;
    transform: scale(1.05);
    cursor: default;
}

.see-all-link:hover {
    color: #8c4dff;
    transform: scale(1.1);
    transition: transform 0.3s ease;
    cursor: pointer;

}


/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(0px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.goals-section-overview,
.reminders-section,
.coming-soon-section {
    animation: fadeIn 0.5s ease forwards;
}
